import React from "react";

const AudioPlayer = (props) => (
    <React.Fragment>
        <h5 className="mb-4">Posłuchaj o miejscu:</h5>
        <audio controls className="mb-4">
            <source src={props.src} type="audio/mpeg" />
        </audio>
    </React.Fragment>
);

export default AudioPlayer;
