import { Link, navigate } from "gatsby";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Alert,
} from "reactstrap";
import Button from "../../components/atoms/Button/Button";
import Heading from "../../components/atoms/Heading/Heading";
import Icon from "../../components/atoms/Icon/Icon";
import LocationMarker from "../../components/atoms/LocationMarker/LocationMarker";
import Paragraph from "../../components/atoms/Paragraph/Paragraph";
import SlickSlider from "../../components/atoms/SlickSlider/SlickSlider";
import TaskMarker from "../../components/atoms/TaskMarker/TaskMarker";
import PlaceMarker from "../../components/atoms/PlaceMarker/PlaceMarker";
import RouteListItem from "../../components/molecules/RouteListItem/RouteListItem";
import YoutubeVideo from "../../components/atoms/YoutubeVideo/YoutubeVideo";
import SEO from "../../components/seo";
import useRoutes from "../../hooks/redux/useRoutes";
import Layout from "../../layout";
import { hideBottomNav, hideHeader, logOut } from "../../state/actions";
import * as LUtils from "../../utils/leafletUtils";
import { useAuth } from "../../hooks/redux";
import PlainText from "../../components/atoms/PlainText/PlainText";
import { useGlobal } from "../../hooks/strapi";

import "./index.scss";
import confettiLeft from "../../assets/images/confetti-left.svg";
import confettiRight from "../../assets/images/confetti-right.svg";
import isBrowser from "../../utils/isBrowser";
import ModalCustom from "../../components/molecules/Modal/Modal";
import RouteTutorialModal from "../../components/molecules/RouteTutorialModal/RouteTutorialModal";
import FavoriteButton from "../../components/molecules/FavoriteButton/FavoriteButton";
import AudioPlayer from "../../components/atoms/AudioPlayer/AudioPlayer";

const { MapContainer, Polyline, TileLayer, useMap } =
    typeof window !== "undefined" ? require("react-leaflet") : {};

let mapRef;
let centerPos;

const LocationButton = ({ setModal }) => {
    const map = useMap();
    const getLocation = () =>
        map.locate({ enableHighAccuracy: true, maximumAge: 0 });
    const [{ routes }, { setRoute }] = useRoutes();

    useEffect(() => {
        if (routes && routes.length) {
            mapRef.flyTo(centerPos);
        }
    }, [routes]);

    mapRef = map;

    return (
        <Container className="btn--fixed-location__container">
            <Row>
                <Col className="d-flex flex-column align-items-end">
                    <Button
                        className="btn--circle page-header--action-btn btn--locate-me"
                        onClick={() => {
                            if (document) {
                                document.body.scrollTop =
                                    document.documentElement.scrollTop = 0;
                            }
                            getLocation();
                        }}
                    >
                        <svg x="0px" y="0px" viewBox="-391 269.3 17.1 24.4">
                            <g transform="translate(-287 -38)">
                                <g>
                                    <path
                                        d="M-92.3,324.8l0.4-2.6l1.6-1c0.3-0.2,0.4-0.5,0.4-0.8l-0.6-4.2c-0.2-1.6-1.6-2.9-3.2-3
                                        c1.5-1,1.9-2.9,0.9-4.4s-2.9-1.9-4.4-0.9c-1.5,1-1.9,2.9-0.9,4.4c0.2,0.4,0.6,0.7,0.9,0.9c-1.6,0.1-3,1.4-3.2,3l-0.6,4.2
                                        c0,0.3,0.1,0.6,0.4,0.8l1.6,1l0.4,2.7c-2.8,0.4-5.2,1.4-5.2,3.1c0,2.3,4.4,3.4,8.5,3.4c4.1,0,8.5-1.1,8.5-3.4
                                        C-86.9,326.2-89.3,325.2-92.3,324.8z M-95.5,308.9c0.9,0,1.6,0.7,1.7,1.6c0,0.9-0.7,1.6-1.6,1.7c-0.9,0-1.6-0.7-1.7-1.6
                                        c0,0,0,0,0,0C-97.1,309.6-96.4,308.9-95.5,308.9z M-99.4,320.1l0.5-3.7c0.1-0.9,0.8-1.6,1.7-1.6h3.3c0.9,0.1,1.6,0.8,1.7,1.6
                                        l0.5,3.7L-93,321c-0.2,0.1-0.3,0.3-0.4,0.6l-0.8,5.8v0c0,0.1-0.1,0.2-0.2,0.3h-2.1c-0.1,0-0.2-0.1-0.2-0.3v0l-0.8-5.8
                                        c0-0.2-0.2-0.4-0.4-0.6L-99.4,320.1z M-90.2,329c-1.7,0.5-3.5,0.7-5.2,0.7c-1.8,0-3.5-0.2-5.2-0.7c-1.3-0.4-1.7-0.9-1.7-1.1
                                        s0.4-0.6,1.7-1.1c0.7-0.2,1.4-0.4,2.1-0.5l0.2,1.3c0.1,0.9,0.9,1.6,1.8,1.6h2.1c0.9,0,1.7-0.7,1.8-1.6l0.2-1.3
                                        c0.8,0.1,1.6,0.3,2.3,0.5c1.3,0.4,1.7,0.9,1.7,1.1S-88.9,328.6-90.2,329L-90.2,329z"
                                    />
                                </g>
                            </g>
                        </svg>
                    </Button>
                    <Button
                        className="btn--circle page-header--action-btn btn--help-me"
                        onClick={() => {
                            setModal(true);
                        }}
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24">
                            <g transform="translate(-104.271 -165.924)">
                                <path d="M116.271,165.924a12,12,0,1,0,12,12A12,12,0,0,0,116.271,165.924Zm0,22a10,10,0,1,1,10-10A10,10,0,0,1,116.271,187.924Z" />
                                <path d="M117.131,171.022a4,4,0,0,0-4.64,2.62.985.985,0,0,0,.612,1.251l.028.009a1.014,1.014,0,0,0,1.26-.66,2.028,2.028,0,0,1,2.28-1.28,2,2,0,0,1,1.4,2.2,1.962,1.962,0,0,1-2.08,1.54,1,1,0,0,0-1.113.873.894.894,0,0,0-.007.127l-.016,3.12a.99.99,0,0,0,.979,1h.021a.991.991,0,0,0,1-.98v-.02l.02-2.18a3.89,3.89,0,0,0,.256-7.62Z" />
                                <path d="M115.891,182.7h-.02a1.029,1.029,0,0,0-1,1v.22a.944.944,0,0,0,.969.92h.031a1.068,1.068,0,0,0,1-1.04v-.16A.96.96,0,0,0,115.891,182.7Z" />
                            </g>
                        </svg>
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

const StartedRoute = ({ params }) => {
    const { id } = params;

    const global = useGlobal();
    const dispatch = useDispatch();
    const [{ routes }, { setRoute }] = useRoutes();
    const [
        auth,
        {
            updatePlaces,
            updateStats,
            updateStartedRoutes,
            updateFinishedRoutes,
            updateFavorites,
        },
    ] = useAuth();
    const route = routes?.find((route) => route?.id.toString() === id);
    const [modal, setModal] = useState(false);
    const [finishModal, setFinishModal] = useState(false);
    const [distanceModal, setDistanceModal] = useState(false);
    const [tutorialModal, setTutorialModal] = useState(true);
    const [paths, setPaths] = useState([]);
    const [signalAlert, setSignalAlert] = useState(false);
    const [map, setMap] = useState();

    const markersRef = useRef([]);

    useEffect(() => {
        dispatch(hideHeader());
        dispatch(hideBottomNav());

        if (!localStorage.getItem("signalAlert")) setSignalAlert(true);
    }, []);

    useEffect(() => {
        const locations = route?.places.map((a) => {
            return [a.lon, a.lat];
        });

        setTimeout(() => {
            locations &&
                mapRef?.fitBounds(locations, {
                    padding: [70, 70],
                });
        }, 100);
    }, [route]);

    // useEffect(() => {
    //     setTimeout(async () => {
    //         await setPaths([]);
    //         await setPaths(makepaths());
    //     }, 100);
    // }, [auth, route]);

    if (!id || typeof window === "undefined") return <></>;

    const maxRank = [...global.rank].sort(
        (r1, r2) => r1.points_from - r2.points_from
    )[global.rank.length - 1];

    const getRank = (pts) => {
        for (const rank of global.rank) {
            if (rank.points_from <= pts && rank.points_to > pts) {
                return rank;
            }
        }

        return maxRank;
    };

    const userRank = getRank(auth?.data?.stats?.points);

    const onTaskFinished = (placeId) => async (_) => {
        try {
            const response = await axios.post(
                `${process.env.STRAPI_API_URL}/users/me/places/${placeId}/${id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${auth.data.jwt}`,
                    },
                }
            );

            setModal(false);
            updateStats(response.data.stats);
            updatePlaces(response.data.places);

            const placesOnThisRoute = response.data.places.filter(
                (place) => place.route === route?.id
            );

            if (
                placesOnThisRoute?.length ===
                route?.places.filter((place) => place.type !== 4).length
            ) {
                setFinishModal(true);
                const marker = markersRef?.current[placeId];
                marker?.setOpacity(0.3);
                updateStartedRoutes(response.data.started_routes);
                updateFinishedRoutes(response.data.finished_routes);
            }
        } catch (error) {
            const { status } = error.response;

            if (status === 401 || status === 403 || status === 405) {
                dispatch(logOut());
                navigate("/app/logowanie");
            }
        }
    };

    const isTaskCompleted = (place) =>
        auth?.data?.places?.find(
            (_) => _.route === route?.id && _.place === place.id
        ) !== undefined;

    const isTaskActive = (place) => {
        for (const _place of route?.places.filter((item) => item.type !== 4)) {
            if (!isTaskCompleted(_place)) {
                return _place.id === place.id;
            }
        }

        return false;
    };

    const markers = route?.places
        .filter((place) => place.type !== 4)
        .map((place, index, self) => (
            <TaskMarker
                key={`${place.id}`}
                position={[place.lon, place.lat]}
                icon={LUtils.getTaskMarkerIcon(place)}
                ref={(ref) => (markersRef.current[place.id] = ref)}
                opacity={isTaskCompleted(place) ? 0.3 : 1}
            />
        ));

    const isPrevTaskCompleted = (startIndex, places) => {
        let prevNotPlaceholderIndex = 0;

        for (let j = (startIndex - 1) % places.length; j > 0; --j) {
            if (places[j].type !== 4) {
                prevNotPlaceholderIndex = j;
                break;
            }
        }

        return isTaskCompleted(places[prevNotPlaceholderIndex]);
    };

    const isNextTaskCompleted = (startIndex, places) => {
        let nextNotPlaceholderIndex = 0;

        for (let j = (startIndex + 2) % places.length; j < places.length; ++j) {
            if (places[j].type !== 4) {
                nextNotPlaceholderIndex = j;
                break;
            }
        }

        return isTaskCompleted(places[nextNotPlaceholderIndex]);
    };

    const makepaths = () => {
        const paths = [];
        const places = route ? [...route.places] : [];

        if (places.length) {
            for (let i = 0; i < places.length; ++i) {
                const prev = places[i];
                const next = places[(i + 1) % places.length];

                const pathOptions = {};

                const prevComplete =
                    prev.type !== 4
                        ? isTaskCompleted(prev)
                        : isPrevTaskCompleted(i, places);
                const nextComplete =
                    next.type !== 4
                        ? isTaskCompleted(next)
                        : isNextTaskCompleted(i, places);

                if (prevComplete && nextComplete) {
                    pathOptions.color = "#5c6a7b";
                } else if (prevComplete && !nextComplete) {
                    pathOptions.color = LUtils.getColor(route);
                } else if (!prevComplete && !nextComplete) {
                    pathOptions.color = LUtils.getColor(route);
                    pathOptions.dashArray = "10, 10";
                } else if (!prevComplete && nextComplete) {
                    pathOptions.color = "#5c6a7b";
                    pathOptions.dashArray = "10, 10";
                }

                paths.push(
                    <Polyline
                        key={prev.id}
                        positions={[
                            [prev.lon, prev.lat],
                            [next.lon, next.lat],
                        ]}
                        pathOptions={pathOptions}
                    />
                );
            }
        }

        return paths;
    };

    const center = route?.places
        ?.map(({ lon, lat }) => [lon, lat])
        ?.reduce(([latA, lonA], [latB, lonB]) => [
            (latA + latB) / 2,
            (lonA + lonB) / 2,
        ]) ?? [0, 0];

    centerPos = center;

    const onShare = () => {
        if (navigator.share) {
            navigator
                .share({
                    title: route?.name,
                    url: `https://promocjachojnice.pl/trasy/${route?.id}`,
                    text: route?.description,
                })
                .catch(console.error);
        }
    };

    const closeAlertHandler = () => {
        localStorage.setItem("signalAlert", true);
        setSignalAlert(false);
    };

    const displayPlacesCountLabel = (
        places = [],
        variants = {
            default: "",
            first: "",
            second: "",
            third: "",
        }
    ) => {
        let placesCountLabel = variants.default;

        if (places) {
            const placesCountLastDigit = places.length
                .toString()
                .split("")
                .pop();

            if (places.length === 1) placesCountLabel = variants.first;
            if (
                places.length === 2 ||
                places.length === 3 ||
                places.length === 4
            )
                placesCountLabel = variants.second;
            if (
                placesCountLastDigit > 1 &&
                placesCountLastDigit < 5 &&
                places.length > 20
            )
                placesCountLabel = variants.third;
        }

        return placesCountLabel;
    };

    return (
        <Layout>
            <SEO title={`Trasa "${route?.name}"`} />
            <Container>
                <Row>
                    <Col>
                        <div className="map-display--actions">
                            <Button
                                className="btn--circle page-header--action-btn"
                                onClick={() =>
                                    isBrowser() ? window.history.back() : ""
                                }
                            >
                                <Icon className="icon-chevron-left-min"></Icon>
                            </Button>
                            <Button
                                className="btn--circle page-header--action-btn"
                                onClick={onShare}
                            >
                                <Icon className="icon-share-min"></Icon>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>

            <MapContainer
                center={[53.0591212, 17.8138172]}
                zoom={11}
                doubleClickZoom={false}
                closePopupOnClick={false}
                dragging={true}
                zoomSnap={false}
                zoomDelta={true}
                trackResize={false}
                touchZoom={true}
                scrollWheelZoom={false}
                className="started-route--map"
                whenCreated={(mapInstance) => {
                    setMap(mapInstance);
                    mapRef = mapInstance;

                    if (route) {
                        const locations = route.places.map((a) => {
                            return [a.lon, a.lat];
                        });

                        mapRef?.fitBounds(locations, {
                            padding: [70, 70],
                        });
                    }
                }}
            >
                <TileLayer
                    attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                    url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png?api_key=1a10a4bf-8b40-4f57-8c93-0129034a2537"
                />
                {markers}
                {paths}
                <LocationMarker />
                <LocationButton setModal={setTutorialModal} />
            </MapContainer>

            <Container className="mb-5 pb-5">
                <Row className="justify-content-center mt-5">
                    <Col lg={10}>
                        {signalAlert && (
                            <Alert color="info" className="signal-alert">
                                Brak zasięgu sieci może powodować problemy z
                                prawidłowym zlokalizowaniem użytkownika na
                                mapie. Zaleca się także aktualizowanie
                                oprogramowania telefonu do najnowszej wersji.
                                <span
                                    className="close"
                                    onClick={closeAlertHandler}
                                >
                                    &times;
                                </span>
                            </Alert>
                        )}
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={10}>
                        <Heading as="h2" className="mb-2">
                            {route?.name}
                        </Heading>
                        <PlainText className="route-status">
                            Odwiedzono{" "}
                            {auth?.isLoggedIn && auth.data.places
                                ? auth?.data.places.filter(
                                      (obj) =>
                                          Number(obj["route"]) === route?.id
                                  ).length + " "
                                : 1 + " "}
                            z{" "}
                            {
                                route?.places.filter(
                                    (place) => place.type !== 4
                                ).length
                            }{" "}
                            miejsc
                        </PlainText>
                    </Col>
                </Row>
                <Row className="justify-content-center mb-5 pb-5">
                    <Col lg={10}>
                        {route?.places
                            .filter((place) => place.type !== 4)
                            .map((place, i, self) => (
                                <RouteListItem
                                    key={i}
                                    index={i}
                                    places={self}
                                    place={place}
                                    route={route}
                                    setModal={setModal}
                                    setDistanceModal={setDistanceModal}
                                    mapRef={map}
                                    markersRef={markersRef}
                                />
                            ))}
                    </Col>
                </Row>
            </Container>
            {modal && (
                <Modal isOpen={Boolean(modal)} toggle={setModal}>
                    <ModalHeader className="modal--header">
                        <div className="title--container">
                            <div className="btn btn--circle btn__active">
                                <Icon className="icon-bulb-min" />
                            </div>
                            <span className="h2 text-primary">
                                {modal.name}
                            </span>
                        </div>
                        <Button
                            className="btn--circle modal--close-modal"
                            onClick={(_) => setModal(false)}
                            aria-label="Zamknij"
                        >
                            <Icon className="icon-close-min" />
                        </Button>
                    </ModalHeader>
                    <ModalBody className="modal--body">
                        <Heading as="h3">{modal.descTitle}</Heading>
                        {
                            modal?.audio?.url && <AudioPlayer src={
                                process.env.STRAPI_API_URL +
                                modal?.audio?.url
                            } />
                        }
                        <Paragraph>{modal.description}</Paragraph>
                        {modal.images.length > 0 && (
                            <div className="slider-with-peek">
                                <SlickSlider>
                                    {modal.images.map((image, index) => (
                                        <div
                                            className="curiosity-image"
                                            key={index}
                                        >
                                            <div className="curiosity-image--wrapper">
                                                <div className="gatsby-image-wrapper">
                                                    <img
                                                        data-main-image
                                                        src={
                                                            process.env
                                                                .STRAPI_API_URL +
                                                            image.url
                                                        }
                                                        alt=""
                                                        style={{ opacity: 1 }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </SlickSlider>
                            </div>
                        )}
                        {modal.video && <YoutubeVideo url={modal.video} />}
                    </ModalBody>
                    {auth.isLoggedIn && (
                        <ModalFooter className="modal--footer">
                            <div>
                                {!isTaskCompleted(modal) && (
                                    <Button
                                        className="btn--primary w-100 mb-3"
                                        onClick={onTaskFinished(modal.id)}
                                    >
                                        Miejsce odwiedzone
                                    </Button>
                                )}
                            </div>
                            <div
                                className="text-center py-3"
                                onClick={() => setModal(false)}
                            >
                                Podążaj dalej trasą
                            </div>
                        </ModalFooter>
                    )}
                </Modal>
            )}
            {finishModal && (
                <Modal isOpen={finishModal} toggle={setFinishModal}>
                    <ModalHeader className="modal--header">
                        <div className="icon--container">
                            <div className="btn btn--circle btn__active">
                                <Icon className="icon-discover-min" />
                            </div>
                        </div>
                        <Button
                            className="btn--circle modal--close-modal"
                            onClick={(_) => setFinishModal(false)}
                            aria-label="Zamknij"
                        >
                            <Icon className="icon-close-min" />
                        </Button>
                    </ModalHeader>
                    <ModalBody className="modal--body finish-modal">
                        <Heading as="h1">
                            Gratulacje! <br />
                            Ukończyłeś trasę
                        </Heading>
                        <Heading as="h2">{route?.name}</Heading>
                        <Heading as="h3">Twój przebyty dystans</Heading>
                        <div className="finish-modal--tags finish-modal--tags__details">
                            <div className="btn--circle__container finish-modal--tag">
                                <Button
                                    className="btn--circle"
                                    style={{ marginBottom: "0.8rem" }}
                                >
                                    <Icon className="icon-distance-min" />
                                </Button>
                                <PlainText className="btn--label text-center">
                                    {`${
                                        Number(
                                            auth?.data?.stats?.["distance"] ?? 0
                                        ) / 1000
                                    } km`}
                                </PlainText>
                            </div>
                        </div>
                        <Heading as="h3">
                            Liczba uzyskanych punktów odkrywcy
                        </Heading>
                        <div className="finish-modal--points-container">
                            <img
                                className="finish-modal--confetti finish-modal--confetti__left"
                                src={confettiLeft}
                                alt=""
                            />
                            <img
                                className="finish-modal--confetti finish-modal--confetti__right"
                                src={confettiRight}
                                alt=""
                            />
                            <div className="finish-modal--points">
                                <Icon className="icon-discover-min" />
                                &nbsp;{route?.points}&nbsp;pkt
                            </div>
                        </div>
                        <div className="finish-modal--progress">
                            <div className="finish-modal--rank">
                                Zostałeś&nbsp;
                                <PlainText
                                    className={`finish-modal--rank__${global.rank.findIndex(
                                        (r) => r.id === userRank?.id
                                    )}`}
                                >
                                    {userRank?.name}
                                </PlainText>
                            </div>

                            <div className="finish-modal--progress-bar">
                                <div
                                    className={`finish-modal--progress-bar-fill finish-modal--rank__${global.rank.findIndex(
                                        (r) => r.id === userRank.id
                                    )}`}
                                    style={{
                                        width: `${
                                            ((auth?.data?.stats?.points ?? 0) /
                                                maxRank.points_from) *
                                            100
                                        }%`,
                                    }}
                                ></div>
                                {global.rank.map((rank, index) => (
                                    <div
                                        key={index}
                                        className={`text-center finish-modal--progress-bar-indicator finish-modal--rank__${global.rank.findIndex(
                                            (r) => r.id === rank.id
                                        )}`}
                                        style={{
                                            left: `${
                                                (rank.points_from /
                                                    maxRank.points_from) *
                                                100
                                            }%`,
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="13"
                                            height="13.176"
                                            viewBox="0 0 16 13.176"
                                        >
                                            <path d="M6.29,2.816a2,2,0,0,1,3.419,0l4.446,7.323a2,2,0,0,1-1.71,3.038H3.554a2,2,0,0,1-1.71-3.038Z" />
                                        </svg>

                                        <div className="mt-1">
                                            {rank.points_from} pkt
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="finish-modal--tiles">
                            <div className="finish-modal--tile">
                                <div className="finish-modal--tile-label">
                                    Dodaj trasę do ulubionych
                                </div>

                                <div className="finish-modal--tile-icon">
                                    <FavoriteButton
                                        route={route}
                                        classes="btn--circle"
                                    />
                                </div>
                            </div>
                            <div className="finish-modal--tile">
                                <div className="finish-modal--tile-label">
                                    Udostępnij trasę
                                </div>

                                <div className="finish-modal--tile-icon">
                                    <Button
                                        className="btn--circle"
                                        onClick={onShare}
                                    >
                                        <Icon className="icon-share-min" />
                                    </Button>
                                </div>
                            </div>
                            <div className="finish-modal--tile">
                                <div className="finish-modal--tile-label">
                                    Podziel się opinią
                                </div>

                                <div className="finish-modal--tile-icon">
                                    <Button
                                        className="btn--circle"
                                        onClick={() =>
                                            navigate("/pomoc", {
                                                state: { form: true },
                                            })
                                        }
                                    >
                                        <Icon className="icon-award-min" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="finish-modal--link">
                            <Link to="/app/moje-trasy">
                                Moje trasy <Icon className="icon-arrow-forth" />
                            </Link>
                        </div>
                    </ModalBody>
                    <ModalFooter className="modal--footer">
                        <div>
                            <Button
                                className="btn--primary w-100 mb-3"
                                onClick={(_) =>
                                    navigate("/app/moje-osiagniecia")
                                }
                            >
                                Zapisz i pokaż profil
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            )}

            <ModalCustom
                className="modal--xs"
                modal={distanceModal}
                setModal={() => setDistanceModal(false)}
                BottomActions={() => (
                    <Button
                        className={`btn--primary btn--md btn-block`}
                        onClick={() => setDistanceModal(false)}
                    >
                        Zamknij
                    </Button>
                )}
            >
                <Heading as="h1">Błędna lokalizacja</Heading>
                <Paragraph>
                    Jesteś zbyt daleko od punktu trasy, aby go odwiedzić.
                </Paragraph>
            </ModalCustom>

            <RouteTutorialModal
                show={tutorialModal}
                setShow={setTutorialModal}
            />
        </Layout>
    );
};

export default StartedRoute;
